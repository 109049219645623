import { motion } from "framer-motion";
import "./building.scss";
import StandardSwiper from "../../components/swiper/standard";
import ColouredSwiper from "../../components/swiper/coloured";
import { ColouredSlides } from "../../utils/building";
import { useState, useEffect } from "react";

const Building = () => {
    const [backgroundPosition, setBackgroundPosition] = useState(0);
    const handleScroll = () => {
        const scrollTop = window.pageYOffset;
        const docHeight =
            document.documentElement.scrollHeight - window.innerHeight;
        const isMobile = window.innerWidth <= 800;
        const scrollPixels =
            (scrollTop / docHeight) * 100 * (isMobile ? 1.5 : 3);
        setBackgroundPosition(scrollPixels);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const baseTrans = {
        duration: 0.25,
        delay: 0.25,
    };

    return (
        <>
            <div className="contain-wrapper">
                <div className="building-one contain">
                    <div
                        className="hero-image"
                        style={{
                            backgroundImage: "url(../images/building/1.jpg)",
                        }}
                    />
                    <div
                        style={{ bottom: `${backgroundPosition}px` }}
                        className="orange-box building-hero-box"
                    >
                        <h4>A CONTEMPORARY AND IMPRESSIVE BUILDING</h4>
                    </div>
                </div>
            </div>
            <div className="contain-wrapper">
                <div className="building-two contain">
                    <motion.h4
                        initial={{ opacity: 0, y: 25 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={baseTrans}
                    >
                        25 COPTHALL AVENUE HAS BEEN FULLY REFURBISHED TO
                        EXACTING MODERN STANDARDS. THE BUILDING FEATURES A
                        WEALTH OF FEATURES INCLUDING CAFE, BUSINESS LOUNGE AND
                        END OF JOURNEY FACILITIES.
                    </motion.h4>
                </div>
            </div>
            <div className="contain-wrapper">
                <div className="building-three contain">
                    <StandardSwiper
                        slides={[
                            "../images/building/gallery/1.jpg",
                            "../images/building/gallery/2.jpg",
                            "../images/building/gallery/3.jpg",
                            "../images/building/gallery/4.jpg",
                            "../images/building/gallery/5.jpg",
                        ]}
                    />
                </div>
            </div>
            <div className="contain-wrapper">
                <div className="building-four contain">
                    <motion.h4
                        initial={{ opacity: 0, y: 25 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={baseTrans}
                    >
                        BUILDING <br /> FEATURES
                    </motion.h4>
                    <div className="swiper-container">
                        <ColouredSwiper slides={ColouredSlides} />
                    </div>
                </div>
            </div>
        </>
    );
};
export default Building;
