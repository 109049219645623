import { Swiper as SwiperComponent, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { motion } from "framer-motion";
import "./standard.scss";

interface StandardSwiperProps {
    slides: string[];
}

const StandardSwiper: React.FC<StandardSwiperProps> = ({ slides }) => {
    return (
        <div className="swiper-comp-container">
        
        <SwiperComponent
            pagination={{ clickable: true, el: ".pagination" }}
            modules={[Pagination, Autoplay, Navigation]}
            centeredSlides={true}
            slidesPerView={1}
            initialSlide={0}
            id='standard-swiper'
            autoHeight={true}
            navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            }}
        >
            {slides.map((slide, index) => (
                <SwiperSlide key={index}>
                    
                        <div
                            className='swiper-image'
                            style={{ backgroundImage: `url(${slide})` }}
                        >
                            <div   
                                className='swiper-caption'
                            >
                            </div>
                        </div>
                    
                </SwiperSlide>
            ))}
            <div className='pagination'></div>
        </SwiperComponent>
            <div className='swiper-button-prev'></div>
            <div className='swiper-button-next'></div>
        </div>
    );
};
export default StandardSwiper;
