import { useEffect, useState } from "react";
import "../../scss/global.scss";
import "./home.scss";
import { motion } from "framer-motion";
import Lottie from "react-lottie";
import animationData from "./data.json";

const Home: React.FC = () => {
    const [backgroundPosition, setBackgroundPosition] = useState(0);

    const handleScroll = () => {
        const scrollTop = window.pageYOffset;
        const docHeight =
            document.documentElement.scrollHeight - window.innerHeight;
        const isMobile = window.innerWidth <= 800;
        const scrollPixels = (scrollTop / docHeight) * 100 * (isMobile ? 1 : 3);
        setBackgroundPosition(scrollPixels);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData,

        rendererSettings: {
            preserveAspectRatio: "xMinYMax meet",
        },
    };

    return (
        <>
            <div className="contain-wrapper">
                <div className="intro">
                    <div className="introline"></div>
                    <div className="introanim">
                        <Lottie options={defaultOptions} />
                    </div>
                    <img src="../images/spellout.png" className="logo" />
                </div>
            </div>
            <div className="contain-wrapper">
                <div className="home-two contain">
                    <motion.h2
                        initial={{ opacity: 0, y: 25 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.25 }}
                    >
                        5,160 SQ FT TO 34,348 SQ FT FULLY REFURBISHED GRADE A
                        OFFICE SPACE SPREAD OVER THREE FLOORS IN THE HEART OF THE
                        CITY, JUST TWO MINUTES FROM THE ELIZABETH LINE.
                    </motion.h2>
                    <div
                        className="backimage"
                        style={{ backgroundImage: "url(../images/home/1.jpg)" }}
                    />
                </div>
            </div>
            <div className="contain-wrapper">
                <div
                    className="home-three contain"
                    style={{ marginTop: `-${backgroundPosition}px` }}
                >
                    <div className="box-wrapper">
                        <div className="orange">
                            <h4>
                            UP TO 34,348 SQ FT <br />
                            FULLY REFURBISHED GRADE A OFFICE SPACE
                            </h4>
                        </div>
                        <div className="blue">
                            <h4>
                                AVAILABLE
                                <br />
                                NOW
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;
