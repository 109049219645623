export const ColouredSlides = [
    {
        icon: '../images/building/icons/1.svg',
        copy: '4 PIPE FAN-COIL AIR CONDITIONING',
    },
    {
        icon: '../images/building/icons/11.svg',
        copy: 'LARGE CENTRAL ATRIUM',
    },
    {
        icon: '../images/building/icons/3.svg',
        copy: 'SUSPENDED CEILINGS WITH LED LIGHTING',
    },
    {
        icon: '../images/building/icons/4.svg',
        copy: '4 GLAZED WALL CLIMBING LIFTS',
    },
    {
        icon: '../images/building/icons/5.svg',
        copy: 'RAISED FLOORS',
    },
    {
        icon: '../images/building/icons/6.svg',
        copy: 'CHANGING FACILITIES & DRYING ROOM',
    },
    {
        icon: '../images/building/icons/7.svg',
        copy: '13 SHOWERS',
    },
    {
        icon: '../images/building/icons/8.svg',
        copy: '114 BICYCLE SPACES',
    },
    {
        icon: '../images/building/icons/9.svg',
        copy: 'GROUND FLOORS BUSINESS LOUNGE',
    },
    {
        icon: '../images/building/icons/10.svg',
        copy: 'GROUND FLOOR CAFE',
    },
];