import { delay, motion } from "framer-motion";
import "./location.scss";
import { useState, useEffect } from "react";
import { occupiers, bars } from "../../utils/location";
const Location = () => {
    const [activeLocation, setActiveLocation] = useState<number>(0);
    const [backgroundPosition, setBackgroundPosition] = useState(0);

    const handleScroll = () => {
        const scrollTop = window.pageYOffset;
        const docHeight =
            document.documentElement.scrollHeight - window.innerHeight;
        const isMobile = window.innerWidth <= 800;
        const scrollPixels =
            (scrollTop / docHeight) * 500 * (isMobile ? 1.5 : 3);
        setBackgroundPosition(scrollPixels);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        if (document.getElementById("orange")) {
            window.addEventListener("scroll", handleScroll);
        }
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const standardTransition = {
        duration: 0.5,
        delay: 0.5,
    };


    return (
        <>
            <div className="contain-wrapper">
                <div className="loc-one contain">
                    <div
                        style={{ top: `${backgroundPosition}px` }}
                        className="orange-box location-hero-box"
                        id="orange"
                    >
                        <h4>IN THE HEART OF THE CITY</h4>
                    </div>
                    <div className="background-image" />
                </div>
            </div>
            <div className="contain-wrapper green-back">
                <div className="loc-two contain">
                    <div className="left-side">
                        <motion.h4 initial={{opacity: 0, y: 25}} whileInView={{opacity: 1, y: 0}} transition={standardTransition}>
                            CENTRAL <br /> LOCATION
                        </motion.h4>
                        <div className="key">
                            <div className="occu">
                                <motion.div
                                initial={{opacity: 0, y: 25}} whileInView={{opacity: 1, y: 0}} transition={standardTransition}
                                    onClick={() => setActiveLocation(0)}
                                    className="title-wrapper"
                                >
                                    <span className="black"></span>
                                    <h5
                                        className={`${
                                            activeLocation == 0 ? "active" : ""
                                        }`}
                                    >
                                        LOCAL OCCUPIERS
                                    </h5>
                                </motion.div>
                                {activeLocation === 0 && (
                                    <ul>
                                        {occupiers.map((occupier, index) => (
                                            <motion.li
                                                initial={{ y: 10, opacity: 0 }}
                                                animate={{ opacity: 1, y: 0 }}
                                                transition={{
                                                    delay: index / 50,
                                                }}
                                                key={index}
                                            >
                                                <p>{occupier}</p>
                                            </motion.li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                            <div className="bars">
                                <motion.div initial={{opacity: 0, y: 25}} whileInView={{opacity: 1, y: 0}} transition={standardTransition}
                                    onClick={() => setActiveLocation(1)}
                                    className="title-wrapper"
                                >
                                    <span className="orange"></span>
                                    <h5
                                        className={`${
                                            activeLocation == 1 ? "active" : ""
                                        }`}
                                    >
                                        BARS & RESTAURANTS
                                    </h5>
                                </motion.div>
                                {activeLocation === 1 && (
                                    <ul>
                                        {bars.map((bar, index) => (
                                            <motion.li
                                                initial={{ y: 10, opacity: 0 }}
                                                animate={{ opacity: 1, y: 0 }}
                                                transition={{
                                                    delay: index / 50,
                                                }}
                                                key={index}
                                            >
                                                <p>{bar}</p>
                                            </motion.li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </div>
                    </div>
                    <div
                        className="map"
                        style={{
                            backgroundImage: "url(../images/location/map.png)",
                        }}
                    ></div>
                </div>
            </div>
            <div className="contain-wrapper beige-back">
                <div className="loc-three contain">
                    <div className="connected-wrapper">
                        <div className="border" />
                        <div className="station">
                            <h4>MOORGATE</h4>
                            <div className="bottom-wrapper">
                                <div className="walk-wrapper">
                                    <img src="../images/location/walk.svg" alt="walker"/>
                                    <p>02</p>
                                </div>
                                <p>MINS</p>
                            </div>
                        </div>
                        <div className="station">
                            <h4>LIVERPOOL<br/>STREET</h4>
                            <div className="bottom-wrapper">
                                <div className="walk-wrapper">
                                <img src="../images/location/navy-walk.svg" alt="walker"/>
                                    <p>06</p>
                                </div>
                                <p>MINS</p>
                            </div>
                        </div>
                        <div className="station">
                            <h4>BANK</h4>
                            <div className="bottom-wrapper">
                                <div className="walk-wrapper">
                                <img src="../images/location/walk.svg" alt="walker"/>
                                    <p>05</p>
                                </div>
                                <p>MINS</p>
                            </div>
                        </div>
                        <div className="station">
                            <h4>FENCHURCH<br/>STREET</h4>
                            <div className="bottom-wrapper">
                                <div className="walk-wrapper">
                                <img src="../images/location/walk.svg" alt="walker"/>
                                    <p>12</p>
                                </div>
                                <p>MINS</p>
                            </div>
                        </div>
                        <div className="station">
                            <h4>HEATHROW<br/>AIRPORT</h4>
                            <div className="bottom-wrapper">
                                <div className="walk-wrapper">
                                    <div className="icon"></div>
                                    <p>41</p>
                                </div>
                                <p>MINS</p>
                            </div>
                        </div>
                        <div className="border" />
                        <div className="station">
                            <h4>WELL<br/>CONNECTED</h4>

                            <p>
                                25 Copthall Avenue is just two minutes walk from
                                the Elizabeth Line and has great connections
                                across London and beyond.
                            </p>
                        </div>
                        <div className="border" />
                    </div>
                </div>
            </div>
            <div className="contain-wrapper">
                <div className="loc-four contain">
                    <div className="left">
                        <motion.h4 initial={{opacity: 0, y: 25}} whileInView={{opacity: 1, y: 0}} transition={standardTransition}>
                            SITUATED IN THE HEART OF THE CITY. 25 COPTHALL
                            AVENUE IS SURROUNDED BY GREAT BARS AND RESTAURANTS.
                        </motion.h4>
                        <img src="../images/location/2.jpg" alt="tables" />
                    </div>
                    <div className="right">
                        <img src="../images/location/3.jpg" />
                        <div className="border"></div>
                        <img src="../images/location/4.jpg" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Location;
