import { Swiper as SwiperComponent, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./coloured.scss";

interface slide {
    icon: string;
    copy: string;
}
interface ColouredSwiperProps {
    slides: slide[];
}

const ColouredSwiper: React.FC<ColouredSwiperProps> = ({ slides }) => {
    return (
        <div className="coloured-wrapper">
            <div className="border">
            <div className="swiper-button-prev"></div>
            <SwiperComponent
                pagination={{ clickable: true, el: ".pagination" }}
                modules={[Pagination, Autoplay, Navigation]}
                navigation={{
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                }}
                loop={false}
                centeredSlides={false}
                slidesPerView={1}
                initialSlide={0}
                id="coloured-swiper"
                autoHeight={true}
                autoplay={{ delay: 2000 }}
                breakpoints={{
                    800: {
                        slidesPerView: 5,
                    },
                }}
            >
                {slides.map((slide, index) => (
                    <SwiperSlide key={index}>
                        <div className="icon-wrapper">
                            <img src={slide.icon} alt="icon" />
                        </div>
                        <div className="copy">
                            <h4>{slide.copy}</h4>
                        </div>
                    </SwiperSlide>
                ))}
                <div className="pagination"></div>
            </SwiperComponent>
            <div className="swiper-button-next"></div>
            </div>
        </div>
    );
};

export default ColouredSwiper;
