import { m } from "framer-motion";

export const bars = [ "Off the Wall at The Ape Hotel",
    "The Natural Kitchen",
    "Notes",
    "Temper",
    "Coya",
    "Mint Leaf Lounge",
    "Brasserie Blanc",
    "Burger and Lobster",
    "City Social",
    "Balls Brothers",
    "Duck and Waffle",
    "Ivy City Garden",
    "Kitty Hawk",
    "Fox Fine Wine",
    "Davy's at Woodgate",
    "Hawksmoor",
    "The Anthologist",
    "Harry's Bar",
    "Jackson and Rye",
    "Coq D'Argent",
    "The Ned"];


export const occupiers = [ "Habib Bank AG Zurich", "Chartered Accountants Hall", "BlackRock", "ING Bank", "Baker Botts", "Bank of England", "White & Case", "Charles Stanley Pan Asset", "Deutsche Bank", "UBS", "CAP", "Bloomberg LP", "Fox Williams", "Unicredit", "CLSA (UK)", "Cleary Gottlieb Steen & Hamilton", "BNP Paribas", "Hewlett Packard Enterprise", "City of London Corportation", "Investec Wealth & Investment", "Commerzbank", "Debevoise & Plimpton", "Milbank", "DLA Piper UK LLP"]
