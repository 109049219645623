import { motion } from "framer-motion";
import "./footer.scss";

const Footer: React.FC = () => {
    return (
        <>
            <div className="contain-wrapper" id="contact">
                <div className="footer-wrapper contain" id="contact">
                    <div className="dl">
                        <a href="../downloads/presenter.pdf" target="_blank">
                            <motion.h4
                                initial={{ opacity: 0, y: 25 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.25, delay: 0.25 }}
                            >
                                DOWNLOAD BROCHURE
                            </motion.h4>
                        </a>
                    </div>
                    <div className="contact">
                        <motion.h5
                            initial={{ opacity: 0, y: 25 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.25, delay: 0.25 }}
                        >
                            CONTACT
                        </motion.h5>
                    </div>
                    <div className="agents">
                        <div className="img-wrapper">
                            <motion.img
                                initial={{ opacity: 0, y: 25 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.25, delay: 0.25 }}
                                className="gerald"
                                src="../images/geraldeve.svg"
                                alt="geraldeve"
                            />
                        </div>
                        <div className="agent">
                            <motion.h4
                                initial={{ opacity: 0, y: 25 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.25, delay: 0.25 }}
                            >
                                AMY BRYANT
                            </motion.h4>
                            <motion.p
                                initial={{ opacity: 0, y: 25 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.25, delay: 0.25 }}
                            >
                                +44 (0)755 117 2931
                            </motion.p>
                            <a href="mailto:Amy.Bryant@nmrk.com">
                                <motion.p
                                    initial={{ opacity: 0, y: 25 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.25, delay: 0.25 }}
                                >
                                    Amy.Bryant@nmrk.com
                                </motion.p>
                            </a>
                        </div>
                        <div className="agent">
                            <motion.h4
                                initial={{ opacity: 0, y: 25 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.25, delay: 0.25 }}
                            >
                                FERGUS JAGGER
                            </motion.h4>
                            <motion.p
                                initial={{ opacity: 0, y: 25 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.25, delay: 0.25 }}
                            >
                                +44 (0)778 755 8756
                            </motion.p>
                            <a href="mailto:Fergus.Jagger@nmrk.com">
                                <motion.p
                                    initial={{ opacity: 0, y: 25 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.25, delay: 0.25 }}
                                >
                                    Fergus.Jagger@nmrk.com
                                </motion.p>
                            </a>
                        </div>
                    </div>
                    <div className="agents">
                        <div className="img-wrapper">
                            <motion.img
                                initial={{ opacity: 0, y: 25 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.25, delay: 0.25 }}
                                className="squarebook"
                                src="../images/squarebrook.svg"
                                alt="squarebrook"
                            />
                        </div>
                        <div className="agent">
                            <motion.h4
                                initial={{ opacity: 0, y: 25 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.25, delay: 0.25 }}
                            >
                                RUPERT PERKINS
                            </motion.h4>
                            <motion.p
                                initial={{ opacity: 0, y: 25 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.25, delay: 0.25 }}
                            >
                                +44 (0)781 689 1755
                            </motion.p>
                            <a href="mailto:rupert.perkins@squarebook.com">
                                <motion.p
                                    initial={{ opacity: 0, y: 25 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.25, delay: 0.25 }}
                                >
                                    rupert.perkins@squarebook.com
                                </motion.p>
                            </a>
                        </div>
                        <div className="agent">
                            <motion.h4
                                initial={{ opacity: 0, y: 25 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.25, delay: 0.25 }}
                            >
                                ZARA BAILEY
                            </motion.h4>
                            <motion.p
                                initial={{ opacity: 0, y: 25 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.25, delay: 0.25 }}
                            >
                                +44 (0)758 459 9382
                            </motion.p>
                            <a href="zara.bailey@squarebook.com">
                                <motion.p
                                    initial={{ opacity: 0, y: 25 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.25, delay: 0.25 }}
                                >
                                    zara.bailey@squarebook.com
                                </motion.p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="siren">
                <a href="https://sirendesign.co.uk/">
                    <p>Designed and Developed by Siren</p>
                </a>
            </div>
        </>
    );
};

export default Footer;
